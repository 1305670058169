import { Controller } from "@hotwired/stimulus";
import { setCookie } from "./cookies";

export default class extends Controller {
    set(event) {
        if (event.params.name !== "") {
            setCookie(event.params.name, event.target.value, { path: location.path });
        } else {
            console.warn("missing cookie name param");
        }
    }

    delete(event) {
        let { name, path } = event.params;
        if (name === "") {
            console.warn("missing cookie name param");
            return;
        }
        if (path === "") {
            path = "/";
            return;
        }

        setCookie(event.params.name, "", { path: path, expires: new Date(0) });
    }
}
