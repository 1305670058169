import { Application } from "@hotwired/stimulus";

import listFilterController from "./list-filter-controller.js";
import navigationController from "./navigation-controller.js";
import destinationsController from "./destinations-controller.js";
import focusAnimalsController from "./focus-animals-controller.js";
import accordionController from "./accordion-controller.js";
import dropdownController from "./dropdown-controller.js";
import promotionBannerController from "./promotion-banner-controller.js";
import travelTipsOverlayController from "./travel-tips-overlay-controller.js";
import showMoreController from "./show-more-controller.js";
import modalController from "./modal-controller.js";
import programNavigationController from "./program-navigation-controller.js";
import clickToScrollXController from "./click-to-scroll-x-controller.ts";
import dialogController from "./dialog-controller.ts";
import selfDestructController from "./self-destruct-controller.js";
import morphdomFrameController from "./morphdom-frame-controller";
import turboFetchingController from "./turbo-fetching-controller.js";
import carouselController from "./carousel-controller.ts";
import imageSwipersController from "./image-swipers-controller.js";
import scrollController from "./scroll-controller.ts";
import mapController from "./map-controller.ts";
import lazyClassController from "./lazy-class-controller.js";
import programPage__intersectionsController from "./program-page__intersections-controller.js";
import copyIconController from "./copy-icon-controller.js";
import userMenuController from "./user-menu-controller.js";
import clipboardController from "./clipboard-controller.js";
import showMeController from "./show-me-controller.js";
import turboFrameController from "./turbo-frame-controller.js";
import clickOutsideController from "./click-outside-controller.js";
import youtubeVideoController from "./youtube-video-controller.js";
import formErrorResetController from "./form-error-reset-controller.js";
import toggleController from "./toggle-controller.js";
import flashController from "./flash-controller.js";
import domController from "./dom-controller.js";
import webStorageController from "./web-storage-controller.js";
import dispatchController from "./dispatch-controller.js";
import isEmptyInputController from "./is-empty-input-controller.js";
import isPlaceholderSelectedController from "./is-placeholder-selected-controller.js";
import copySelectionController from "./copy-selection-controller.js";
import detectOutsideClick from "./detect-outside-click.js";
import mirrorAttributeController from "./mirror-attribute-controller.js";
import passwordCheckController from "./password-check-controller.js";
import paymentPage__insurance__intersectionsController from "./payment-page__insurance__intersections-controller.js";
import scrollToFragmentController from "./scroll-to-fragment-controller.js";
import scrollXController from "./scroll-x-controller.ts";
import anchorTabController from "./anchor-tab-controller.js";
import reloadController from "./reload-controller.js";
import intercomController from "./intercom-controller.js";
import rangeController from "./range-controller.js";
import copyValueController from "./copy-value-controller.js";
import filterSettingsController from "./filter-settings-controller.js";
import filterPageController from "./filter-page-controller.js";
import quickNavbarMobileController from "./quick-navbar-mobile-controller.js";
import languageCurrencyDialogController from "./language-currency-dialog-controller.js";
import birthdateStartValueController from "./birthdate-start-value-controller.js";
import celloController from "./cello-controller.js";
import programApplicationProjectOverviewController from "./program-application-project-overview-controller.js";
import cookieController from "./cookie-controller.js";
import landingPageController from "./landing-page-controller.js";
import messagesController from "./messages-controller.js";
import programApplicationDocumentsController from "./program-application-documents-controller.js";
import urlController from "./url-controller.js";
import formController from "./form-controller.js";
import turboStreamController from "./turbo-stream-controller.js";
import turboController from "./turbo-controller.js";

window.Stimulus = Application.start();

Stimulus.register("list-filter", listFilterController);
Stimulus.register("navigation", navigationController);
Stimulus.register("destinations", destinationsController);
Stimulus.register("focus-animals", focusAnimalsController);
Stimulus.register("accordion", accordionController);
Stimulus.register("dropdown", dropdownController);
Stimulus.register("promotion-banner", promotionBannerController);
Stimulus.register("travel-tips-overlay", travelTipsOverlayController);
Stimulus.register("show-more", showMoreController);
Stimulus.register("modal", modalController);
Stimulus.register("program-navigation", programNavigationController);
Stimulus.register("click-to-scroll-x", clickToScrollXController);
Stimulus.register("dialog", dialogController);
Stimulus.register("self-destruct", selfDestructController);
Stimulus.register("morphdom-frame", morphdomFrameController);
Stimulus.register("turbo-fetching", turboFetchingController);
Stimulus.register("carousel", carouselController);
Stimulus.register("image-swipers", imageSwipersController);
Stimulus.register("scroll", scrollController);
Stimulus.register("map", mapController);
Stimulus.register("lazy-class", lazyClassController);
Stimulus.register("program-page-intersections", programPage__intersectionsController);
Stimulus.register("copy-icon", copyIconController);
Stimulus.register("user-menu", userMenuController);
Stimulus.register("clipboard", clipboardController);
Stimulus.register("show-me", showMeController);
Stimulus.register("turbo-frame", turboFrameController);
Stimulus.register("click-outside", clickOutsideController);
Stimulus.register("detect-outside-click", detectOutsideClick);
Stimulus.register("youtube-video", youtubeVideoController);
Stimulus.register("form-error-reset", formErrorResetController);
Stimulus.register("toggle", toggleController);
Stimulus.register("flash", flashController);
Stimulus.register("dom", domController);
Stimulus.register("web-storage", webStorageController);
Stimulus.register("dispatch", dispatchController);
Stimulus.register("is-empty-input", isEmptyInputController);
Stimulus.register("is-placeholder-selected", isPlaceholderSelectedController);
Stimulus.register("copy-selection", copySelectionController);
Stimulus.register("mirror-attribute", mirrorAttributeController);
Stimulus.register("password-check", passwordCheckController);
Stimulus.register("payment-page-insurance-intersections", paymentPage__insurance__intersectionsController);
Stimulus.register("scroll-to-fragment", scrollToFragmentController);
Stimulus.register("scroll-x-controller", scrollXController);
Stimulus.register("anchor-tab", anchorTabController);
Stimulus.register("reload", reloadController);
Stimulus.register("intercom", intercomController);
Stimulus.register("range", rangeController);
Stimulus.register("copy-value", copyValueController);
Stimulus.register("filter-settings", filterSettingsController);
Stimulus.register("filter-page", filterPageController);
Stimulus.register("quick-navbar-mobile", quickNavbarMobileController);
Stimulus.register("language-currency-dialog", languageCurrencyDialogController);
Stimulus.register("birthdate-start-value", birthdateStartValueController);
Stimulus.register("cello", celloController);
Stimulus.register("program-application-project-overview", programApplicationProjectOverviewController);
Stimulus.register("cookie", cookieController);
Stimulus.register("landing-page", landingPageController);
Stimulus.register("messages", messagesController);
Stimulus.register("program-application-documents", programApplicationDocumentsController);
Stimulus.register("url", urlController);
Stimulus.register("form", formController);
Stimulus.register("turbo-stream", turboStreamController);
Stimulus.register("turbo", turboController);
