import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.check();
    }
    check() {
        let isEmpty = true;

        if (this.element.tagName === "SELECT") {
            const selectedOption = this.element.options[this.element.selectedIndex];
            if (this.element.value !== "" || selectedOption.value !== "") {
                isEmpty = false;
            }
        } else {
            if (this.element.value !== "") {
                isEmpty = false;
            }
        }

        if (isEmpty) {
            this.element.setAttribute("data-is-empty", "");
        } else {
            this.element.removeAttribute("data-is-empty");
        }
    }
}
