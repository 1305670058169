import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    submitAfterInput(event) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            const { submitButtonId } = event.params;
            const submitBtn = this.element.querySelector("#" + submitButtonId);
            if (!submitBtn) {
                console.warn(`missing submit button ${submitButtonId}`);
                return;
            }
            submitBtn.click();
        }, 250);
    }

    submitViaButton(event) {
        const { buttonId } = event.params;
        const btn = this.element.querySelector("#" + buttonId);
        if (!btn) {
            console.warn(`cannot find button ${btn}`);
            return;
        }
        btn.click();
    }

    submit(event) {
        const { formId } = event.params;
        if (formId) {
            const form = this.element.querySelector("#" + formId);
            if (!form) {
                console.warn(`cannot find form ${formId}`);
                return;
            }
            form.submit();
            return;
        }
        if (this.element.tagName === "FORM") {
            this.element.submit();
            return;
        }
        console.warn(`cannot find form ${formId}`);
    }
}
