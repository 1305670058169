import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    stopPropagation(event) {
        event.stopPropagation();
    }
    shouldExecute(event, constraintValue) {
        if (!constraintValue) {
            return true;
        }
        const matchCheck = /([^:]+):([^(]+)\(([^)]*)\)/;
        const [_, targetSelector, func, args] = matchCheck.exec(constraintValue);
        if (func === "hasAttribute") {
            let targetElement = event.target;
            if (targetSelector !== "&") {
                targetElement = this.element.querySelector(targetSelector);
            }
            if (targetElement.hasAttribute(args)) {
                return true;
            }
        } else {
            console.warn(`${this.identifier}: unknown function ${func} in check value ${constraintValue}`);
        }
        return false;
    }

    kebabToCC(str) {
        return str.replace(/-./g, (match) => match[1].toUpperCase());
    }

    modify(event) {
        const groups = this.groupsFor(event, 1, true);
        this._modify(groups);
    }

    modify2(event) {
        const groups = this.groupsFor(event, 2);
        this._modify(groups);
    }

    groupsFor(event, no, ifNotMatch = false) {
        const { groups: groupsString, group } = event.params;
        let groups = [];
        if (group && group !== "") {
            groups.push(group);
        }
        if (groupsString && groupsString !== "") {
            const tmp = groupsString.split(" ").map((e) => e.trim());
            groups.push(...tmp);
        }
        groups = groups.filter((group) => {
            let parts = group.split("#");
            if (parts.length > 1) {
                if (parseInt(parts[1]) === no) {
                    return true;
                } else {
                    return false;
                }
            }
            parts = group.split("@");
            if (parts.length > 1) {
                if (parts[1] === event.type) {
                    return true;
                } else {
                    return false;
                }
            }
            return ifNotMatch;
        });
        groups = groups.map((group) => {
            let parts = group.split("#");
            if (parts.length > 1) {
                return parts[0];
            }
            parts = group.split("@");
            if (parts.length > 1) {
                return parts[0];
            }
            return group;
        });
        groups = groups.filter((group) => {
            const constraint = event.params[`${this.kebabToCC(group)}OnlyIf`];
            return this.shouldExecute(event, constraint);
        });
        return groups;
    }

    _modify(groups) {
        for (const group of groups) {
            const actionsAttribute = `data-${this.identifier}-${group}-actions`;
            const actionAttribute = `data-${this.identifier}-${group}-action`;
            const targetsSelector = `[${actionsAttribute}], [${actionAttribute}]`;
            const targets = [this.element, ...this.element.querySelectorAll(targetsSelector)];
            for (const target of targets) {
                const actions = [];
                if (target.hasAttribute(actionAttribute)) {
                    actions.push(target.getAttribute(actionAttribute));
                }
                if (target.hasAttribute(actionsAttribute)) {
                    const tmp = target
                        .getAttribute(actionsAttribute)
                        .split(" ")
                        .map((e) => e.trim());
                    actions.push(...tmp);
                }
                for (const action of actions) {
                    if (action === "hide") {
                        target.setAttribute("hidden", "");
                    } else if (action === "show") {
                        target.removeAttribute("hidden");
                    } else if (action === "toggle") {
                        if (target.hasAttribute("hidden")) {
                            target.removeAttribute("hidden");
                        } else {
                            target.setAttribute("hidden", "");
                        }
                    } else if (action === "remove") {
                        target.remove();
                    } else if (action === "hide-after") {
                        const secsAttribute = `data-${this.identifier}-${group}-hide-after-sec`;
                        const secs = target.getAttribute(secsAttribute);
                        setTimeout(
                            () => {
                                target.setAttribute("hidden", "");
                            },
                            parseInt(secs) * 1000,
                        );
                    } else if (action === "remove-after") {
                        const secsAttribute = `data-${this.identifier}-${group}-remove-after-sec`;
                        const secs = target.getAttribute(secsAttribute);
                        setTimeout(
                            () => {
                                target.remove();
                            },
                            parseInt(secs) * 1000,
                        );
                    } else if (action === "replace-class") {
                        const oldAttribute = `data-${this.identifier}-${group}-replace-class-old`;
                        const oldClassValue = target.getAttribute(oldAttribute);
                        const newAttribute = `data-${this.identifier}-${group}-replace-class-new`;
                        const newClassValue = target.getAttribute(newAttribute);
                        const oldClassList = oldClassValue.split(" ").map((s) => s.trim());
                        target.classList.remove(...oldClassList);
                        const newClassList = newClassValue.split(" ").map((s) => s.trim());
                        target.classList.add(...newClassList);
                    } else if (action === "add-class") {
                        const addClassAttribute = `data-${this.identifier}-${group}-add-class`;
                        const newClassValue = target.getAttribute(addClassAttribute);
                        const newClassList = newClassValue.split(" ").map((s) => s.trim());
                        target.classList.add(...newClassList);
                    } else if (action === "remove-class") {
                        const removeClassAttribute = `data-${this.identifier}-${group}-remove-class`;
                        const removeClassValue = target.getAttribute(removeClassAttribute);
                        const removeClassList = removeClassValue.split(" ").map((s) => s.trim());
                        target.classList.remove(...removeClassList);
                    } else if (action === "toggle-class") {
                        const toggleClassAttribute = `data-${this.identifier}-${group}-toggle-class`;
                        const toggleClassValue = target.getAttribute(toggleClassAttribute);
                        const toggleClassList = toggleClassValue.split(" ").map((s) => s.trim());
                        if (toggleClassList.length == 0) {
                            continue;
                        }
                        if (target.classList.contains(toggleClassList[0])) {
                            target.classList.remove(...toggleClassList);
                        } else {
                            target.classList.add(...toggleClassList);
                        }
                    } else if (action === "remove-attribute") {
                        const removeAttributeName = `data-${this.identifier}-${group}-remove-attribute`;
                        const removeAttributeValue = target.getAttribute(removeAttributeName);
                        const attributes = removeAttributeValue.split(" ").map((e) => e.trim());
                        for (const a of attributes) {
                            target.removeAttribute(a);
                        }
                    } else if (action === "add-attribute") {
                        const addAttributeName = `data-${this.identifier}-${group}-add-attribute-name`;
                        const addAttributeNameValue = target.getAttribute(addAttributeName);
                        const addAttributeValueAttributeName = `data-${this.identifier}-${group}-add-attribute-value`;
                        const addAttributeValueAttributeValue =
                            target.getAttribute(addAttributeValueAttributeName) ?? "";
                        if (!target.hasAttribute(addAttributeNameValue)) {
                            target.setAttribute(addAttributeNameValue, addAttributeValueAttributeValue);
                        }
                        if (target.getAttribute(addAttributeNameValue) !== addAttributeValueAttributeValue) {
                            target.setAttribute(addAttributeNameValue, addAttributeValueAttributeValue);
                        }
                    } else if (action === "focus") {
                        if (target.focus) {
                            target.focus();
                        }
                    } else if (action === "toggle-input-type") {
                        const attributeName = `data-${this.identifier}-${group}-switch-input-type`;
                        const attributeValue = target.getAttribute(attributeName);
                        target.setAttribute(attributeName, target.getAttribute("type"));
                        target.setAttribute("type", attributeValue);
                    } else if (action === "empty-value") {
                        target.value = "";
                    }
                }
            }
        }
    }
}
